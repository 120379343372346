import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Breadcrumbs, StepBase, CtaCard, Loader } from 'src/subframe/index';
import { TextField } from 'src/subframe/components/TextField';
import { IconWithBackground } from 'src/subframe/components/IconWithBackground';
import Page from 'components/Page';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { Button } from 'src/subframe/components/Button';
import { ActionType, CloudConnection, CloudType } from 'api/models';
import {
  createCloudConnection,
  createCloudConnectionAction,
  getCloudConnection,
} from 'api/frontend';
import { useSnackbar } from 'notistack';
import { formatDistanceToNow } from 'date-fns';
import { RouterLink } from 'components/RouterLink';
import { TabsWithContent } from 'components/design-system/Tabs';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { CopyToClipboard } from 'src/components/design-system/CopyToClipboard';
import * as Sentry from '@sentry/browser';
import { Badge } from 'subframe/index';
import {
  charLimitExceededErrorMessage,
  charLimitLessThanMinErrorMessage,
  charLimits,
  gcpProjectIdRegex,
  invalidNameInputErrorMessage,
  validNameRegex,
} from 'constants/input-validation';

type ProcessingState = 'pending' | 'processing' | 'completed' | 'failed';

interface WorkflowState {
  // creation form
  projectId: string;
  cloudAccountName: string;

  // processing state
  creationState: ProcessingState;
  verificationState: ProcessingState;
  secondState: ProcessingState;

  // UI folding state
  firstOpen: boolean;
  secondOpen: boolean;
  thirdOpen: boolean;
}

function creationStateToStepVariant(
  state: 'pending' | 'processing' | 'completed' | 'failed',
): 'default' | 'success' | 'loading' | 'error' {
  if (state === 'pending') {
    return 'default';
  }
  if (state === 'processing') {
    return 'loading';
  }
  if (state === 'completed') {
    return 'success';
  }
  if (state === 'failed') {
    return 'error';
  }

  return 'default';
}

export default function CloudAccountsAddNewEditGCP() {
  const { logEvent } = AnalyticsEventLogger();
  const { id } = useParams();
  const { account } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const basePathCloudAccounts = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/cloud_accounts/',
  );
  const basePathClusters = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters/',
  );
  const EditRequestError = somethingWentWrong.replace(
    '<action>',
    'adding this Cloud Account',
  );
  const LoadRequestError = somethingWentWrong.replace(
    '<action>',
    'fetching this Cloud Account',
  );

  useEffect(() => {
    logEvent('cloud-account-add-new-edit-page-viewed', {
      cloud_account_id: id,
      cloud_account_type: 'gcp',
    });
  }, []);

  // TODO: this should come from the backend but for now better here than nowhere
  const formSchema = yup.object().shape({
    projectId: yup
      .string()
      .min(
        charLimits.GCPProjectIdMin,
        charLimitLessThanMinErrorMessage(
          'GCP Project ID',
          charLimits.GCPProjectIdMin,
        ),
      )
      .max(
        charLimits.GCPProjectIdMax,
        charLimitExceededErrorMessage(
          'GCP Project ID',
          charLimits.GCPProjectIdMax,
        ),
      )
      .matches(gcpProjectIdRegex, 'Invalid GCP Project ID'),
    cloudAccountName: yup
      .string()
      .optional()
      .max(
        charLimits.Name,
        charLimitExceededErrorMessage('Cloud Account Name', charLimits.Name),
      )
      .matches(validNameRegex, invalidNameInputErrorMessage),
  });

  const [connection, setConnection] = useState<CloudConnection | null>(null);
  const [workflowState, setWorkflowState] = useState<WorkflowState>({
    projectId: '',
    cloudAccountName: '',

    creationState: 'pending',
    verificationState: 'pending',

    firstOpen: id === undefined,
    secondOpen: id !== undefined,
    thirdOpen: true,
    secondState: 'pending',
  });

  const validateConnection = async (connection: CloudConnection) => {
    try {
      setWorkflowState((prev) => ({
        ...prev,
        verificationState: 'processing',
      }));

      await createCloudConnectionAction(
        connection.id,
        { action_type: ActionType.VERIFY },
        { headers: { Authorization: `Bearer ${account?.token}` } },
      );
      const result = await getCloudConnection(connection.id, {
        headers: { Authorization: `Bearer ${account?.token}` },
      });
      setConnection(result);
      if (result.status === 'connected') {
        setWorkflowState((prev) => ({
          ...prev,
          verificationState: 'completed',

          firstOpen: false,
          secondOpen: false,
          secondState: 'completed',
          thirdOpen: true,
        }));
        logEvent('cloud-account-verify-connection-to-chkk-marked-as-done');
      }
    } catch (error) {
      setWorkflowState((prev) => ({ ...prev, verificationState: 'failed' }));
      // TODO
    }
  };

  const createHandler = async (projectId: string, cloudAccountName: string) => {
    setWorkflowState((prev) => ({ ...prev, creationState: 'processing' }));

    try {
      await formSchema.validate({ projectId }, { abortEarly: false });
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        (err as yup.ValidationError).inner.forEach((innerErr) =>
          enqueueSnackbar(innerErr.message, {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          }),
        );
        setWorkflowState((prev) => ({
          ...prev,
          creationState: 'failed',
          firstOpen: true,
        }));
      }
      return;
    }

    // data is valid
    try {
      const result = await createCloudConnection(
        {
          cloud_type: CloudType.GCP,
          cloud_connection_name: cloudAccountName,
          gcp: {
            project_id: projectId,
            is_organization: false,
          },
        },
        {
          headers: { Authorization: `Bearer ${account?.token}` },
        },
      );
      setConnection(result);
      setWorkflowState((prev) => ({
        ...prev,
        creationState: 'completed',
        firstOpen: false,
        secondOpen: true,
      }));
    } catch (error) {
      enqueueSnackbar(EditRequestError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      Sentry.captureException(error);
      setWorkflowState((prev) => ({ ...prev, creationState: 'failed' }));
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      const retrieve = async () => {
        try {
          setWorkflowState((prev) => ({
            ...prev,
            creationState: 'processing',
          }));
          const result = await getCloudConnection(id, {
            headers: { Authorization: `Bearer ${account?.token}` },
          });
          const resultConnected = result.status === 'connected';
          setConnection(result);
          setWorkflowState((prev) => ({
            ...prev,
            projectId: result.gcp?.project_id || '',
            cloudAccountName: result.cloud_connection_name || '',

            creationState: 'completed',
            verificationState:
              result.status === 'connected' ? 'completed' : 'processing',

            firstOpen: false,
            secondOpen: !resultConnected,
            secondState: resultConnected ? 'completed' : 'pending',
            thirdOpen: true,
          }));
        } catch (error) {
          enqueueSnackbar(LoadRequestError, {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          });
          Sentry.captureException(error);
          setWorkflowState((prev) => ({ ...prev, creationState: 'failed' }));
        }
      };
      retrieve().catch(console.error);
    }
  }, [id]);

  useEffect(() => {
    if (connection !== null) {
      if (connection.status === 'connected') {
        return;
      }
      const interval = setInterval(async () => {
        await validateConnection(connection);
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [connection]);

  return (
    <Page title="Cloud Accounts">
      <div className="flex bg-default-background pl-3 pr-3 py-8 flex-col gap-6 items-start h-full ml-8 w-[calc(100%-64px)]">
        <Breadcrumbs>
          <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <RouterLink to={basePathCloudAccounts}>
            <Breadcrumbs.Item>Cloud Accounts</Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          {id === undefined && (
            <Breadcrumbs.Item active={true}>Add GCP Account</Breadcrumbs.Item>
          )}
          {id && <Breadcrumbs.Item active={true}>{id}</Breadcrumbs.Item>}
        </Breadcrumbs>
        <div className="flex flex-col gap-2 items-start w-full">
          <div className="flex flex-[1_0_0] h-full w-full flex-col gap-2 items-start">
            {id === undefined && (
              <span className="text-default-font text-section-header">
                Add GCP Account
              </span>
            )}
            {id && (
              <span className="text-default-font text-section-header">
                GCP Project: {connection?.gcp?.project_id}
              </span>
            )}
            {id === undefined && (
              <span className="text-label text-subtext-color">
                Connect your GCP Project to Chkk
              </span>
            )}
            {id && (
              <span className="text-label text-subtext-color">ID: {id}</span>
            )}
          </div>
        </div>
        {id !== undefined && connection === null && (
          <div className="flex w-full flex-col gap-2 items-center">
            <Loader size="large" />
          </div>
        )}
        {(id === undefined || connection !== null) && (
          <div className="flex flex-col gap-3 items-start w-full">
            <StepBase
              stepTitle="Enter Project ID"
              stepBody="It takes less than 1 min to onboard a GCP Project to Chkk."
              lastStep={false}
              stepNumber="1"
              open={workflowState.firstOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => ({ ...prev, firstOpen: open }));
                open && logEvent('cloud-account-enter-account-id-opened');
              }}
              variant={creationStateToStepVariant(workflowState.creationState)}
              actionButtons={
                <Button
                  disabled={
                    workflowState.creationState === 'processing' ||
                    workflowState.creationState === 'completed' ||
                    id !== undefined
                  }
                  variant="brand-secondary"
                  size="medium"
                  icon="FeatherCornerDownRight"
                  loading={workflowState.creationState === 'processing'}
                  onClick={() => {
                    createHandler(
                      workflowState.projectId,
                      workflowState.cloudAccountName,
                    );
                    logEvent('cloud-account-enter-account-id-marked-as-done');
                  }}
                  data-cy="cloud_account_gcp-mark-done"
                >
                  Mark as done
                </Button>
              }
            >
              <div className="flex gap-2 items-center w-full">
                <span className="text-default-font text-body">
                  Enter your GCP Project ID
                </span>
                <TextField>
                  <TextField.Input
                    className="flex-[0_0_auto] w-[384px] h-8"
                    value={workflowState.projectId}
                    disabled={
                      workflowState.creationState === 'processing' ||
                      workflowState.creationState === 'completed' ||
                      id !== undefined
                    }
                    onChange={(evt) =>
                      setWorkflowState((prev) => ({
                        ...prev,
                        projectId: evt.target.value,
                      }))
                    }
                  />
                </TextField>
              </div>
              <div className="flex gap-2 items-center w-full">
                <Badge variant="neutral">Optional</Badge>
                <span className="text-default-font text-body">
                  Enter Account Name
                </span>
                <TextField>
                  <TextField.Input
                    className="flex-[0_0_auto] w-[384px] h-8"
                    disabled={
                      workflowState.creationState === 'processing' ||
                      workflowState.creationState === 'completed' ||
                      id !== undefined
                    }
                    value={workflowState.cloudAccountName}
                    onChange={(evt) =>
                      setWorkflowState((prev) => ({
                        ...prev,
                        cloudAccountName: evt.target.value,
                      }))
                    }
                  />
                </TextField>
              </div>
            </StepBase>
            <StepBase
              stepTitle="Setup Environment"
              stepBody="Run the following command to create the needed resources in your GCP Project:"
              stepNumber="2"
              variant={creationStateToStepVariant(workflowState.secondState)}
              open={workflowState.secondOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => ({
                  ...prev,
                  secondOpen: open,
                }));
                open && logEvent('cloud-account-setup-environment-opened');
              }}
              actionButtons={
                <Button
                  disabled={
                    workflowState.creationState !== 'completed' ||
                    workflowState.secondState === 'completed'
                  }
                  variant="brand-secondary"
                  size="medium"
                  icon="FeatherCornerDownRight"
                  loading={workflowState.creationState === 'processing'}
                  onClick={() => {
                    setWorkflowState((prev) => ({
                      ...prev,
                      secondState: 'completed',
                      secondOpen: false,
                      thirdOpen: true,
                    }));
                    logEvent('cloud-account-setup-environment-marked-as-done');
                  }}
                  data-cy="cloud_account_gcp-mark-done"
                >
                  Mark as done
                </Button>
              }
            >
              <TabsWithContent
                tabs={[
                  {
                    id: 'manualCLI',
                    title: 'Manual (CLI)',
                    content: (
                      <ol className="ml-[2rem] text-body text-default-font w-[calc(100%-2rem)]">
                        <li className="mb-6">
                          <div className="flex flex-col gap-2 w-[calc(100%-2rem)]">
                            Open a terminal and login to your GCP Project:
                            <CopyToClipboard
                              language={connection ? 'shell' : 'plaintext'}
                              text={
                                connection
                                  ? 'gcloud auth login'
                                  : 'Complete Step 1 first'
                              }
                            />
                          </div>
                        </li>
                        <li className="mb-6">
                          <div className="flex flex-col gap-2 w-[calc(100%-2rem)]">
                            Create the IAM policy binding granting Chkk access
                            to your Project:
                            <CopyToClipboard
                              language={connection ? 'shell' : 'plaintext'}
                              text={
                                connection
                                  ? `gcloud projects add-iam-policy-binding ${connection?.gcp?.project_id} --member=serviceAccount:${connection?.gcp?.iam.chkk_service_account_email} --role 'roles/viewer'`
                                  : 'Complete Step 1 first'
                              }
                            />
                          </div>
                        </li>
                      </ol>
                    ),
                  },
                  {
                    id: 'terraform',
                    title: 'Terraform',
                    content: (
                      <div className="flex flex-[1_0_0] h-full w-full flex-col gap-2 items-start">
                        <div className="text-default-font text-body">
                          You can create the IAM role bindings using Terraform:
                        </div>
                        <CopyToClipboard
                          language={connection ? 'shell' : 'plaintext'}
                          text={
                            connection
                              ? `resource "google_project_iam_member" "chkk_connect" {
  project = var.gcloud_project
  role    = "roles/viewer"
  member  = "serviceAccount:${connection?.gcp?.iam.chkk_service_account_email}"
}`
                              : 'Complete Step 1 first'
                          }
                        />
                      </div>
                    ),
                  },
                ]}
                onTabChange={(tabId) => {
                  logEvent('cloud-account-setup-environment-tab-changed', {
                    tab_id: tabId,
                  });
                }}
              />
            </StepBase>
            <StepBase
              stepTitle="Verify Connection to Chkk"
              stepBody={
                connection?.status === 'connected'
                  ? `This account was successfully connected to Chkk ${formatDistanceToNow(
                      new Date(connection.created * 1000),
                      { addSuffix: true },
                    )}.\nClick Redo if you have made changes to this cloud account and want to reverify the connection to Chkk.`
                  : 'Verifying that the GCP Project is successfully connected to Chkk'
              }
              lastStep={true}
              stepNumber="3"
              open={workflowState.thirdOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => ({
                  ...prev,
                  fourthOpen: open,
                }));
                open &&
                  logEvent('cloud-accounts-verify-connection-to-chkk-opened');
              }}
              variant={creationStateToStepVariant(
                workflowState.verificationState,
              )}
              actionButtons={
                <>
                  {connection?.status === 'connected' && (
                    <Button
                      disabled={
                        workflowState.verificationState === 'processing'
                      }
                      variant="brand-secondary"
                      size="medium"
                      icon="FeatherCornerDownRight"
                      loading={workflowState.verificationState === 'processing'}
                      onClick={async () => {
                        if (connection != null) {
                          validateConnection(connection);
                        }
                      }}
                    >
                      Redo
                    </Button>
                  )}
                </>
              }
            >
              {undefined}
            </StepBase>
            {connection?.status === 'connected' && (
              <RouterLink to={basePathClusters} className="w-full">
                <CtaCard
                  className="h-auto w-full flex-none"
                  leftSlot={
                    <IconWithBackground
                      variant="success"
                      size="medium"
                      icon="FeatherCoffee"
                    />
                  }
                >
                  <span className="text-subheader font-subheader text-default-font">
                    🎉 Your cloud account is connected!
                  </span>
                  <span className="text-body font-body text-default-font">
                    Cloud metadata is being collected. Availability Risks will
                    appear in the Clusters page.
                  </span>
                </CtaCard>
              </RouterLink>
            )}
          </div>
        )}
      </div>
    </Page>
  );
}
