import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Breadcrumbs, StepBase, CtaCard, Loader } from 'src/subframe/index';
import { TextField } from 'src/subframe/components/TextField';
import { LearnMoreAccordion } from 'subframe/components/LearnMoreAccordion';
import { IconWithBackground } from 'src/subframe/components/IconWithBackground';
import Page from 'components/Page';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { Button } from 'src/subframe/components/Button';
import { ActionType, CloudConnection, CloudType } from 'api/models';
import {
  createCloudConnection,
  createCloudConnectionAction,
  getCloudConnection,
} from 'api/frontend';
import { useSnackbar } from 'notistack';
import { Badge } from 'src/subframe/components/Badge';
import { formatDistanceToNow } from 'date-fns';
import { RouterLink } from 'components/RouterLink';
import { TabsWithContent } from 'components/design-system/Tabs';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { CopyToClipboard } from 'src/components/design-system/CopyToClipboard';
import * as Sentry from '@sentry/browser';
import { Text } from '@subframe/core';
import {
  awsAccountIdRegex,
  awsSupportedRegionsRegex,
  charLimitExceededErrorMessage,
  charLimits,
  fieldIsRequiredErrorMessage,
  invalidNameInputErrorMessage,
  validNameRegex,
} from 'constants/input-validation';

type ProcessingState = 'pending' | 'processing' | 'completed' | 'failed';

interface WorkflowState {
  // creation form
  accountId: string;
  deployRegion: string;
  cloudAccountName: string;

  // processing state
  creationState: ProcessingState;
  verificationState: ProcessingState;
  secondState: ProcessingState;

  // UI folding state
  firstOpen: boolean;
  secondOpen: boolean;
  thirdOpen: boolean;
}

function creationStateToStepVariant(
  state: 'pending' | 'processing' | 'completed' | 'failed',
): 'default' | 'success' | 'loading' | 'error' {
  if (state === 'pending') {
    return 'default';
  }
  if (state === 'processing') {
    return 'loading';
  }
  if (state === 'completed') {
    return 'success';
  }
  if (state === 'failed') {
    return 'error';
  }

  return 'default';
}

export default function CloudAccountsAddNewEditAWS() {
  const { logEvent } = AnalyticsEventLogger();
  const { id } = useParams();
  const { account } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const basePathCloudAccounts = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/cloud_accounts/',
  );
  const basePathClusters = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters/',
  );
  const EditRequestError = somethingWentWrong.replace(
    '<action>',
    'adding this Cloud Account',
  );
  const LoadRequestError = somethingWentWrong.replace(
    '<action>',
    'fetching this Cloud Account',
  );
  useEffect(() => {
    logEvent('cloud-account-add-new-edit-page-viewed', {
      cloud_account_id: id,
      cloud_account_type: 'aws',
    });
  }, []);

  // TODO: this should come from the backend but for now better here than nowhere
  const formSchema = yup.object().shape({
    accountId: yup
      .string()
      .matches(
        awsAccountIdRegex,
        'AWS Account ID needs to be exactly 12 digits numeric value',
      ),
    deployRegion: yup
      .string()
      .required(fieldIsRequiredErrorMessage('AWS Region'))
      .matches(
        awsSupportedRegionsRegex,
        'AWS Region is invalid or not supported',
      ),
    cloudAccountName: yup
      .string()
      .optional()
      .max(
        charLimits.Name,
        charLimitExceededErrorMessage('Cloud Account Name', charLimits.Name),
      )
      .matches(validNameRegex, invalidNameInputErrorMessage),
  });

  const [connection, setConnection] = useState<CloudConnection | null>(null);
  const [workflowState, setWorkflowState] = useState<WorkflowState>({
    accountId: '',
    deployRegion: 'us-east-1',
    cloudAccountName: '',

    creationState: 'pending',
    verificationState: 'pending',

    firstOpen: id === undefined,
    secondOpen: id !== undefined,
    thirdOpen: true,
    secondState: 'pending',
  });

  const validateConnection = async (connection: CloudConnection) => {
    try {
      setWorkflowState((prev) => ({
        ...prev,
        verificationState: 'processing',
      }));

      await createCloudConnectionAction(
        connection.id,
        { action_type: ActionType.VERIFY },
        { headers: { Authorization: `Bearer ${account?.token}` } },
      );
      const result = await getCloudConnection(connection.id, {
        headers: { Authorization: `Bearer ${account?.token}` },
      });
      setConnection(result);
      if (result.status === 'connected') {
        setWorkflowState((prev) => ({
          ...prev,
          verificationState: 'completed',

          firstOpen: false,
          secondOpen: false,
          secondState: 'completed',
          thirdOpen: true,
        }));
        logEvent('cloud-account-verify-connection-to-chkk-marked-as-done');
      }
    } catch (error) {
      setWorkflowState((prev) => ({ ...prev, verificationState: 'failed' }));
      // TODO
    }
  };

  const createHandler = async (
    accountId: string,
    deployRegion: string,
    cloudAccountName: string,
  ) => {
    setWorkflowState((prev) => ({ ...prev, creationState: 'processing' }));

    try {
      await formSchema.validate(
        { accountId, deployRegion },
        { abortEarly: false },
      );
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        (err as yup.ValidationError).inner.forEach((innerErr) =>
          enqueueSnackbar(innerErr.message, {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          }),
        );
        setWorkflowState((prev) => ({
          ...prev,
          creationState: 'failed',
          firstOpen: true,
        }));
      }
      return;
    }
    // data is valid
    try {
      const result = await createCloudConnection(
        {
          cloud_type: CloudType.AWS,
          cloud_connection_name: cloudAccountName,
          aws: {
            aws_account_id: accountId,
            deploy_region: deployRegion,
          },
        },
        {
          headers: { Authorization: `Bearer ${account?.token}` },
        },
      );
      setConnection(result);
      setWorkflowState((prev) => ({
        ...prev,
        creationState: 'completed',
        firstOpen: false,
        secondOpen: true,
      }));
    } catch (error) {
      enqueueSnackbar(EditAWSRequestError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      Sentry.captureException(error);
      setWorkflowState((prev) => ({ ...prev, creationState: 'failed' }));
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      const retrieve = async () => {
        try {
          setWorkflowState((prev) => ({
            ...prev,
            creationState: 'processing',
          }));
          const result = await getCloudConnection(id, {
            headers: { Authorization: `Bearer ${account?.token}` },
          });
          const resultConnected = result.status === 'connected';
          setConnection(result);
          setWorkflowState((prev) => ({
            ...prev,
            accountId: result.aws?.aws_account_id || '',
            deployRegion: result.aws?.deploy_region || '',
            cloudAccountName: result.cloud_connection_name || '',

            creationState: 'completed',
            verificationState:
              result.status === 'connected' ? 'completed' : 'processing',

            firstOpen: false,
            secondOpen: !resultConnected,
            secondState: resultConnected ? 'completed' : 'pending',
            thirdOpen: true,
          }));
        } catch (error) {
          enqueueSnackbar(LoadAWSRequestError, {
            variant: 'error',
            autoHideDuration: toastAutoHideDuration,
          });
          Sentry.captureException(error);
          setWorkflowState((prev) => ({ ...prev, creationState: 'failed' }));
        }
      };
      retrieve().catch(console.error);
    }
  }, [id]);

  useEffect(() => {
    if (connection !== null) {
      if (connection.status === 'connected') {
        return;
      }
      const interval = setInterval(async () => {
        await validateConnection(connection);
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [connection]);

  return (
    <Page title="Cloud Accounts">
      <div className="flex bg-default-background pl-3 pr-3 py-8 flex-col gap-6 items-start h-full ml-8 w-[calc(100%-64px)]">
        <Breadcrumbs>
          <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <RouterLink to={basePathCloudAccounts}>
            <Breadcrumbs.Item>Cloud Accounts</Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          {id === undefined && (
            <Breadcrumbs.Item active={true}>Add AWS Account</Breadcrumbs.Item>
          )}
          {id && <Breadcrumbs.Item active={true}>{id}</Breadcrumbs.Item>}
        </Breadcrumbs>
        <div className="flex flex-col gap-2 items-start w-full">
          <div className="flex flex-[1_0_0] h-full w-full flex-col gap-2 items-start">
            {id === undefined && (
              <span className="text-default-font text-section-header">
                Add AWS Account
              </span>
            )}
            {id && (
              <span className="text-default-font text-section-header">
                AWS Account: {connection?.aws?.aws_account_id}
              </span>
            )}
            {id === undefined && (
              <span className="text-label text-subtext-color">
                Connect your AWS Account to Chkk
              </span>
            )}
            {id && (
              <span className="text-label text-subtext-color">ID: {id}</span>
            )}
          </div>
        </div>
        {id !== undefined && connection === null && (
          <div className="flex w-full flex-col gap-2 items-center">
            <Loader size="large" />
          </div>
        )}
        {(id === undefined || connection !== null) && (
          <div className="flex flex-col gap-3 items-start w-full">
            <StepBase
              stepTitle="Enter Account ID"
              stepBody="It takes less than 1 min to onboard an AWS account to Chkk."
              lastStep={false}
              stepNumber="1"
              open={workflowState.firstOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => ({ ...prev, firstOpen: open }));
                open && logEvent('cloud-account-enter-account-id-opened');
              }}
              variant={creationStateToStepVariant(workflowState.creationState)}
              actionButtons={
                <Button
                  disabled={
                    workflowState.creationState === 'processing' ||
                    workflowState.creationState === 'completed' ||
                    id !== undefined
                  }
                  variant="brand-secondary"
                  size="medium"
                  icon="FeatherCornerDownRight"
                  loading={workflowState.creationState === 'processing'}
                  onClick={() => {
                    createHandler(
                      workflowState.accountId,
                      workflowState.deployRegion,
                      workflowState.cloudAccountName,
                    );
                    logEvent('cloud-account-enter-account-id-marked-as-done');
                  }}
                  data-cy="cloud_account_aws-mark-done"
                >
                  Mark as done
                </Button>
              }
            >
              <div className="flex gap-2 items-center w-full">
                <span className="text-default-font text-body">
                  Enter your AWS Account ID
                </span>
                <TextField>
                  <TextField.Input
                    className="flex-[0_0_auto] w-[384px] h-8"
                    value={workflowState.accountId}
                    disabled={
                      workflowState.creationState === 'processing' ||
                      workflowState.creationState === 'completed' ||
                      id !== undefined
                    }
                    onChange={(evt) =>
                      setWorkflowState((prev) => ({
                        ...prev,
                        accountId: evt.target.value,
                      }))
                    }
                  />
                </TextField>
              </div>
              <div className="flex gap-2 items-center w-full">
                <span className="text-default-font text-body">
                  Enter AWS Region
                </span>
                <TextField>
                  <TextField.Input
                    className="flex-[0_0_auto] w-[384px] h-8"
                    disabled={
                      workflowState.creationState === 'processing' ||
                      workflowState.creationState === 'completed' ||
                      id !== undefined
                    }
                    value={workflowState.deployRegion}
                    onChange={(evt) =>
                      setWorkflowState((prev) => ({
                        ...prev,
                        deployRegion: evt.target.value,
                      }))
                    }
                  />
                </TextField>
              </div>
              <div className="flex gap-2 items-center w-full">
                <Badge variant="neutral">Optional</Badge>
                <span className="text-default-font text-body">
                  Enter Account Name
                </span>
                <TextField>
                  <TextField.Input
                    className="flex-[0_0_auto] w-[384px] h-8"
                    disabled={
                      workflowState.creationState === 'processing' ||
                      workflowState.creationState === 'completed' ||
                      id !== undefined
                    }
                    value={workflowState.cloudAccountName}
                    onChange={(evt) =>
                      setWorkflowState((prev) => ({
                        ...prev,
                        cloudAccountName: evt.target.value,
                      }))
                    }
                  />
                </TextField>
              </div>
            </StepBase>
            <StepBase
              stepTitle="Setup Environment"
              stepBody="Run the following command to create the needed resources in your AWS account:"
              stepNumber="2"
              variant={creationStateToStepVariant(workflowState.secondState)}
              open={workflowState.secondOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => ({
                  ...prev,
                  secondOpen: open,
                }));
                open && logEvent('cloud-account-setup-environment-opened');
              }}
              actionButtons={
                <Button
                  disabled={
                    workflowState.creationState !== 'completed' ||
                    workflowState.secondState === 'completed'
                  }
                  variant="brand-secondary"
                  size="medium"
                  icon="FeatherCornerDownRight"
                  loading={workflowState.creationState === 'processing'}
                  onClick={() => {
                    setWorkflowState((prev) => ({
                      ...prev,
                      secondState: 'completed',
                      secondOpen: false,
                      thirdOpen: true,
                    }));
                    logEvent('cloud-account-setup-environment-marked-as-done');
                  }}
                  data-cy="cloud_account_aws-mark-done"
                >
                  Mark as done
                </Button>
              }
            >
              <TabsWithContent
                tabs={[
                  {
                    id: 'cloudformationConsole',
                    title: 'CloudFormation (Console)',
                    content: (
                      <div className="flex flex-[1_0_0] h-full w-full flex-col gap-2 items-start">
                        <div className="flex gap-2 items-center">
                          <span className="text-default-font text-body">
                            You can create the IAM role using the Chkk-provided
                            CloudFormation template using the AWS Console:{' '}
                          </span>
                          <a
                            href={connection?.aws?.cfn.console_redirect_url}
                            target="_blank"
                            rel="noreferrer"
                            className="no-underline"
                          >
                            <Button
                              size="medium"
                              icon="FeatherNavigation"
                              onClick={() =>
                                logEvent(
                                  'cloud-accounts-setup-environment-aws-btn-clicked',
                                )
                              }
                            >
                              Open AWS Console
                            </Button>
                          </a>
                        </div>
                        <div className="flex pl-0 pr-0 py-4 flex-col gap-2 items-start">
                          <span className="text-body text-default-font whitespace-pre-wrap">
                            {
                              'Make sure to acknowledge the creation of IAM resources, for the stack creation to work correctly. After finishing the wizard, wait for the stack to reach the UPDATE_COMPLETE state.\n'
                            }
                          </span>
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: 'cloudformationCli',
                    title: 'CloudFormation (CLI)',
                    content: (
                      <div className="flex flex-[1_0_0] h-full w-full flex-col gap-2 items-start">
                        <div className="w-full flex flex-col gap-2">
                          <span className="text-default-font text-body">
                            You can create the IAM role using the Chkk-provided
                            CloudFormation template using the following
                            command-line:
                          </span>
                          <CopyToClipboard
                            language={connection ? 'shell' : 'plaintext'}
                            text={
                              connection
                                ? `aws cloudformation create-stack --stack-name ${connection?.aws?.iam.chkk_role_name} --template-url ${connection?.aws?.cfn.template_url} --parameters ParameterKey=TrustedChkkPrincipal,ParameterValue=${connection?.aws?.iam.trusted_chkk_principal} ParameterKey=ChkkRoleName,ParameterValue=${connection?.aws?.iam.chkk_role_name} ParameterKey=ChkkExternalID,ParameterValue=${connection?.aws?.iam.external_id} --capabilities CAPABILITY_NAMED_IAM --region ${connection?.aws?.deploy_region}`
                                : 'Complete Step 1 first'
                            }
                          />
                        </div>
                        <LearnMoreAccordion title="Learn more about this command">
                          <div className="flex px-3 py-2 flex-[1_0_0] h-full w-full flex-col gap-2 items-start">
                            <span className="text-default-font text-body">
                              Environment Variables:
                            </span>
                            <span className="w-full text-body text-default-font">
                              CHKK_STACK_NAME is the CloudFormation Stack to
                              connect your AWS account to Chkk
                            </span>
                            <span className="text-default-font text-body">
                              CHKK_PRINCIPAL is the IAM Principal which has
                              permission to use the Chkk IAM ReadOnly Role
                            </span>
                            <span className="text-default-font text-body">
                              CHKK_ROLE_NAME is the ReadOnly IAM Role in your
                              account which controls which resources Chkk can
                              access
                            </span>
                            <span className="text-default-font text-body">
                              [Do not edit] CHKK_EXTERNAL_ID is used to provide
                              secure access to your AWS account.
                            </span>
                          </div>
                        </LearnMoreAccordion>
                        <div className="w-full flex flex-col gap-2">
                          <span className="text-default-font text-body">
                            After you kicked off the stack creation, let&#39;s
                            verify that the CloudFormation stack is successfully
                            created:
                          </span>
                          <CopyToClipboard
                            language={connection ? 'shell' : 'plaintext'}
                            text={
                              connection
                                ? `aws cloudformation describe-stacks --stack-name ${connection?.aws?.iam.chkk_role_name} --region ${connection?.aws?.deploy_region}`
                                : 'Complete Step 1 first'
                            }
                          />
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: 'manualConsole',
                    title: 'Manual (Console)',
                    content: (
                      <ol className="w-[calc(100%-2rem)] ml-[2rem] text-body text-default-font">
                        <li className="mb-6">
                          Navigate to the AWS role creator. Choose AWS Account
                          for Trusted entity type and select Another AWS
                          Account.
                        </li>
                        <li className="mb-6">
                          <div className="flex flex-col gap-2">
                            Paste the following value into the Account ID field:
                            <CopyToClipboard
                              text={
                                connection
                                  ? connection?.aws?.iam.trusted_chkk_principal
                                  : 'Complete Step 1 first'
                              }
                            />
                          </div>
                        </li>
                        <li className="mb-6">
                          <div className="flex flex-col gap-6">
                            Check the Require external ID option and enter the
                            value below as the External ID:
                            <CopyToClipboard
                              text={
                                connection
                                  ? connection?.aws?.iam.external_id
                                  : 'Complete Step 1 first'
                              }
                            />
                          </div>
                        </li>
                        <li className="mb-6">
                          Confirm that Require MFA is not selected. Click Next:
                          Permissions.
                        </li>
                        <li className="mb-6">
                          Search for the AWS Managed Policy:{' '}
                          <Text variant="body-bold">{`ReadOnlyAccess`}</Text>.
                          Policies are alphabetically ordered in the AWS
                          Console, so you will find{' '}
                          <Text variant="body-bold">{`ReadOnlyAccess`}</Text>{' '}
                          listed towards the end. Check the box next to the
                          policy name.
                        </li>
                        <li className="mb-6">
                          <div className="flex flex-col gap-6">
                            Name the role
                            <CopyToClipboard
                              text={
                                connection
                                  ? connection?.aws?.iam.chkk_role_name
                                  : 'Complete Step 1 first'
                              }
                            />
                            and click Create role. Ensure that the name matches
                            as other role names may prevent Chkk from being able
                            to scan the account.
                          </div>
                        </li>
                      </ol>
                    ),
                  },
                  {
                    id: 'terraform',
                    title: 'Terraform',
                    content: (
                      <div className="flex flex-[1_0_0] h-full w-full flex-col gap-2 items-start">
                        <div className="text-default-font text-body">
                          You can create the IAM role using Terraform with the
                          following module:
                        </div>
                        <CopyToClipboard
                          language={connection ? 'hcl' : 'plaintext'}
                          text={
                            connection
                              ? `module "iam_assumable_role" {
  source  = "terraform-aws-modules/iam/aws//modules/iam-assumable-role"

  trusted_role_arns = [
    # The Chkk IAM Principal which has permission to use the Chkk IAM Role. [do not edit!]
    "arn:aws:iam::${connection?.aws?.iam.trusted_chkk_principal}:root",
  ]

  create_role = true

  # The IAM Role in your account which controls which resources Chkk can access. [do not edit!]
  role_name         = "${connection?.aws?.iam.chkk_role_name}"

  # External ID is used to provide secure access to your AWS account. [do not edit!]
  role_sts_externalid = "${connection?.aws?.iam.external_id}"

  role_requires_mfa   = false

  custom_role_policy_arns = [
    "arn:aws:iam::aws:policy/ReadOnlyAccess",
  ]
}`
                              : 'Complete Step 1 first'
                          }
                        />
                      </div>
                    ),
                  },
                ]}
                onTabChange={(tabId) => {
                  logEvent('cloud-account-setup-environment-tab-changed', {
                    tab_id: tabId,
                  });
                }}
              />
            </StepBase>
            <StepBase
              stepTitle="Verify Connection to Chkk"
              stepBody={
                connection?.status === 'connected'
                  ? `This account was successfully connected to Chkk ${formatDistanceToNow(
                      new Date(connection.created * 1000),
                      { addSuffix: true },
                    )}.\nClick Redo if you have made changes to this cloud account and want to reverify the connection to Chkk.`
                  : 'Verifying that the AWS Account is successfully connected to Chkk'
              }
              lastStep={true}
              stepNumber="3"
              open={workflowState.thirdOpen}
              onOpenChange={(open) => {
                setWorkflowState((prev) => ({
                  ...prev,
                  fourthOpen: open,
                }));
                open &&
                  logEvent('cloud-accounts-verify-connection-to-chkk-opened');
              }}
              variant={creationStateToStepVariant(
                workflowState.verificationState,
              )}
              actionButtons={
                <>
                  {connection?.status === 'connected' && (
                    <Button
                      disabled={
                        workflowState.verificationState === 'processing'
                      }
                      variant="brand-secondary"
                      size="medium"
                      icon="FeatherCornerDownRight"
                      loading={workflowState.verificationState === 'processing'}
                      onClick={async () => {
                        if (connection != null) {
                          validateConnection(connection);
                        }
                      }}
                    >
                      Redo
                    </Button>
                  )}
                </>
              }
            ></StepBase>
            {connection?.status === 'connected' && (
              <RouterLink to={basePathClusters} className="w-full">
                <CtaCard
                  className="h-auto w-full flex-none"
                  leftSlot={
                    <IconWithBackground
                      variant="success"
                      size="medium"
                      icon="FeatherCoffee"
                    />
                  }
                >
                  <span className="text-subheader font-subheader text-default-font">
                    🎉 Your cloud account is connected!
                  </span>
                  <span className="text-body font-body text-default-font">
                    Cloud metadata is being collected. Availability Risks will
                    appear in the Clusters page.
                  </span>
                </CtaCard>
              </RouterLink>
            )}
          </div>
        )}
      </div>
    </Page>
  );
}
