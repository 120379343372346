import * as Sentry from '@sentry/browser';
import TokenService from '@services/TokenService';
import * as SubframeCore from '@subframe/core';
import auth0, { AuthorizeOptions } from 'auth0-js';
import { toastAutoHideDuration } from 'constants/toasts';
import useSessionStorage from 'hooks/useSessionStorage';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'subframe/components/Button';
import { LinkButton } from 'subframe/components/LinkButton';
import { OAuthSocialButton } from 'subframe/components/OAuthSocialButton';
import { TextField } from 'subframe/components/TextField';
import { generateCodeChallenge, generateRandomString } from './pkceUtils';

const SIMPLE_EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export function makeAuth0JsClient(): auth0.WebAuth {
  const [searchParams] = useSearchParams();
  return new auth0.WebAuth({
    domain: import.meta.env.VITE_AUTH0_DOMAIN || 'auth.us-east-1.aws.chkk.dog',
    clientID:
      import.meta.env.VITE_AUTH0_CLIENTID || 'NLIYq5XfIHp0CGcz2xakU37gFuONF7Rq',
    redirectUri: `${window.location.origin}/login?redirect_to=${
      searchParams.get('redirect_to') || '/orgs'
    }`, // persist redirect_to, to avoid losing direct access url when not logged in
    audience: 'https://!false-it-funny-because-it-true',
    responseType: 'code',
  });
}

export default function Login() {
  const [magicLinkStep, setMagicLinkStep] = useState<'email' | 'code'>('email');
  const [magicLinkEmail, setMagicLinkEmail] = useState('');
  const [magicLinkCode, setMagicLinkCode] = useState('');
  const [processingLogin, setProcessingLogin] = useState(false);
  const [waitingForAuth0, setWaitingForAuth0] = useState<boolean>(false);
  const LoginError = 'Login failed. Try again or escalate to Chkk';

  const [pkceNonce] = useSessionStorage<string>(
    'pkce-nonce',
    generateRandomString(16),
  );
  const [pkceState] = useSessionStorage<string>(
    'pkce-state',
    generateRandomString(16),
  );
  const [pkceCodeVerifier] = useSessionStorage<string>(
    'pkce-code-verifier',
    generateRandomString(96),
  );

  const { enqueueSnackbar } = useSnackbar();
  const { loginWithUserToken, loginWithFederationToken, isAuthenticated } =
    TokenService();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const auth0JS = makeAuth0JsClient();

  useEffect(() => {
    const process = async (): Promise<void> => {
      try {
        if (searchParams.has('federation_token')) {
          const federationToken = searchParams.get('federation_token') || '';
          if (await loginWithFederationToken(federationToken)) {
            navigate(searchParams.get('redirect_to') || '/orgs');
          } else {
            enqueueSnackbar('Login failed. Try again or escalate to Chkk', {
              variant: 'error',
              autoHideDuration: toastAutoHideDuration,
            });
            Sentry.captureException(LoginError, { level: 'log' }); // internal admin login error, log will suffice
          }
        } else if (
          searchParams.has('state') &&
          (searchParams.has('code') || searchParams.has('error'))
        ) {
          if (searchParams.get('state') != pkceState) {
            enqueueSnackbar(LoginError, {
              variant: 'error',
              autoHideDuration: toastAutoHideDuration,
            });
            Sentry.captureException(LoginError);
          } else if (searchParams.has('error')) {
            enqueueSnackbar(LoginError, {
              variant: 'error',
              autoHideDuration: toastAutoHideDuration,
            });
            if (searchParams.get('error') === 'access_denied') {
              Sentry.captureException(LoginError, { level: 'log' }); // handled, user denied access to Google/Microsoft/Github account for login
            } else {
              Sentry.captureException(LoginError);
            }
          } else {
            const code = searchParams.get('code') || '';
            const tokenDetails = await new Promise<{
              idToken: string;
              accessToken: string;
              refreshToken: string;
              expiresIn: number;
            }>((resolve, reject) => {
              auth0JS.client.oauthToken(
                {
                  grantType: 'authorization_code',
                  code: code,
                  codeVerifier: pkceCodeVerifier,
                  redirectUri: `${window.location.origin}/login?redirect_to=/`,
                },
                (err, res) => {
                  if (err) {
                    reject(err);
                  } else {
                    resolve(
                      res as {
                        idToken: string;
                        accessToken: string;
                        refreshToken: string;
                        expiresIn: number;
                      },
                    );
                  }
                },
              );
            });
            const { idToken, accessToken, refreshToken, expiresIn } =
              tokenDetails;
            await new Promise<{
              email: string;
              name: string;
              picture: string;
            }>((resolve, reject) =>
              auth0JS.validateToken(idToken || '', pkceNonce, (err, res) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(
                    res as { email: string; name: string; picture: string },
                  );
                }
              }),
            );
            if (
              await loginWithUserToken(
                idToken || '',
                accessToken || '',
                refreshToken || '',
                expiresIn || 0,
              )
            ) {
              navigate(searchParams.get('redirect_to') || '/orgs');
            }
          }
        } else if (await isAuthenticated()) {
          navigate(searchParams.get('redirect_to') || '/orgs');
        }
      } catch (err) {
        if (
          err.code === 'invalid_grant' &&
          err.description === 'Invalid authorization code'
        ) {
          Sentry.captureException(err, { level: 'log' }); // handled, auth0 token expired or invalid
        } else {
          Sentry.captureException(err);
        }
        enqueueSnackbar(LoginError, {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        });
      } finally {
        setWaitingForAuth0(false);
      }
    };

    if (!waitingForAuth0) {
      setWaitingForAuth0(true);
      void process();
    }
  }, []);

  // enforce mininumum window size
  if (window.innerWidth <= 1000) {
    return <Navigate to="/unsupported-device" />;
  }

  const authorize = async (connection: string): Promise<void> => {
    setProcessingLogin(true);
    const codeChallenge = await generateCodeChallenge(pkceCodeVerifier);
    auth0JS.authorize({
      connection: connection,
      state: pkceState,
      nonce: pkceNonce,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      scope: 'offline_access openid profile email',
    } as AuthorizeOptions);
  };

  const onEmailSubmit = async (toastOnSuccess: boolean): Promise<void> => {
    // validate email
    if (SIMPLE_EMAIL_REGEX.test(magicLinkEmail)) {
      if (
        ['vmware.com'].some(function (domain) {
          const emailSplit = magicLinkEmail.split('@');
          return (
            emailSplit[emailSplit.length - 1].toLowerCase() === domain &&
            emailSplit.length === 2
          );
        })
      ) {
        await authorize('VMWare');
      } else if (
        ['chkk.okta'].some(function (domain) {
          const emailSplit = magicLinkEmail.split('@');
          return (
            emailSplit[emailSplit.length - 1].toLowerCase() === domain &&
            emailSplit.length === 2
          );
        })
      ) {
        await authorize('Chkk-Okta');
      } else if (
        ['chkk.saml'].some(function (domain) {
          const emailSplit = magicLinkEmail.split('@');
          return (
            emailSplit[emailSplit.length - 1].toLowerCase() === domain &&
            emailSplit.length === 2
          );
        })
      ) {
        await authorize('ChkkWorkspaceOne');
      } else if (
        ['cybiware.com'].some(function (domain) {
          const emailSplit = magicLinkEmail.split('@');
          return (
            emailSplit[emailSplit.length - 1].toLowerCase() === domain &&
            emailSplit.length === 2
          );
        })
      ) {
        await authorize('cybiware-gamma');
      } else {
        setProcessingLogin(true);
        auth0JS.passwordlessStart(
          {
            connection: 'email',
            email: magicLinkEmail,
            send: 'code',
          },
          (err) => {
            if (err) {
              setProcessingLogin(false);
              enqueueSnackbar(
                'We were unable to send the code via email. Try again or escalate to Chkk',
                {
                  variant: 'error',
                  autoHideDuration: toastAutoHideDuration,
                },
              );
            } else {
              setProcessingLogin(false);
              if (toastOnSuccess) {
                enqueueSnackbar(
                  'An authentication code has been sent to your inbox. It may take up to 15 minutes for the email to arrive',
                  {
                    variant: 'success',
                    autoHideDuration: toastAutoHideDuration,
                  },
                );
              }
              setMagicLinkStep('code');
            }
          },
        );
      }
    } else {
      enqueueSnackbar('Provide a valid email address', {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    }
  };

  const onCodeSubmit = async (): Promise<void> => {
    if (magicLinkCode) {
      setProcessingLogin(true);
      const codeChallenge = await generateCodeChallenge(pkceCodeVerifier);
      auth0JS.passwordlessLogin(
        {
          connection: 'email',
          email: magicLinkEmail,
          phoneNumber: '',
          verificationCode: magicLinkCode,
          state: pkceState,
          nonce: pkceNonce,
          code_challenge: codeChallenge,
          code_challenge_method: 'S256',
        } as auth0.PasswordlessLoginOptions,
        (err) => {
          if (err) {
            if (
              (err.code === 'access_denied' ||
                err.code === 'too_many_attempts') &&
              err.description
            ) {
              Sentry.captureException(err, { level: 'log' }); // handled, wrong verification code or too many attempts
              enqueueSnackbar(err.description, {
                variant: 'error',
                autoHideDuration: toastAutoHideDuration,
              });
            } else {
              Sentry.captureException(err);
              enqueueSnackbar('Login failed. Try again', {
                variant: 'error',
                autoHideDuration: toastAutoHideDuration,
              });
            }
            setProcessingLogin(false);
          } else {
            // nothing to do as the redirect happens in the library
          }
        },
      );
    } else {
      enqueueSnackbar(
        'Enter the code that was emailed to you. It may take up to 15 minutes for the email to arrive',
        {
          variant: 'warning',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    }
  };

  const onSocialClick = async (
    social: 'msft' | 'google' | 'github',
  ): Promise<void> => {
    switch (social) {
      case 'google':
        await authorize('google-oauth2');
        break;
      case 'github':
        await authorize('github');
        break;
      case 'msft':
        await authorize('azuread');
        break;
    }
  };

  const isLoading = processingLogin || waitingForAuth0;

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-2 bg-neutral-50">
      <div className="flex w-72 flex-col items-center justify-center gap-6 rounded border border-solid border-neutral-border bg-black pt-8 pr-8 pb-8 pl-8">
        <img
          className="w-40 flex-none"
          src="https://res.cloudinary.com/demo/image/upload/v1674762973/Logo-on-dark-with-text_jatcl8.png"
        />

        {isLoading && (
          <div className="flex w-full flex-col items-center justify-center gap-2">
            <SubframeCore.Loader className="text-header font-header text-brand-600" />
            <span className="text-body font-body text-default-font">
              Logging you in...
            </span>
          </div>
        )}

        {!isLoading && magicLinkStep === 'email' && (
          <div className="flex w-full flex-col items-start gap-4">
            <OAuthSocialButton
              className="h-10 w-full flex-none"
              logo="https://res.cloudinary.com/demo/image/upload/v1686350892/github-mark-white_supedx.png"
              onClick={() => void onSocialClick('github')}
            >
              Sign in with Github
            </OAuthSocialButton>
            <OAuthSocialButton
              className="h-10 w-full flex-none"
              logo="https://res.cloudinary.com/demo/image/upload/v1676066751/google-sign-in-button-logo_geeodk.svg"
              onClick={() => void onSocialClick('google')}
            >
              Sign in with Google
            </OAuthSocialButton>
            <OAuthSocialButton
              className="h-10 w-full flex-none"
              logo="https://res.cloudinary.com/demo/image/upload/v1677449795/microsoft_gm7wer.jpg"
              onClick={() => void onSocialClick('msft')}
            >
              Sign in with Microsoft
            </OAuthSocialButton>
            <div className="flex w-full items-center justify-center gap-2">
              <div className="flex h-px w-full grow shrink-0 basis-0 flex-col items-center gap-2 bg-neutral-300" />
              <span className="text-label font-label text-neutral-600">or</span>
              <div className="flex h-px w-full grow shrink-0 basis-0 flex-col items-center gap-2 bg-neutral-300" />
            </div>
            <div className="flex h-full w-full grow shrink-0 basis-0 flex-col items-start gap-2">
              <span className="text-body font-body text-default-font">
                Otherwise, enter your email to sign in or create an account
              </span>
              <TextField className="h-auto w-full flex-none">
                <TextField.Input
                  placeholder="you@work.com"
                  value={magicLinkEmail}
                  onChange={(event) => setMagicLinkEmail(event.target.value)}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      onEmailSubmit(true);
                    }
                  }}
                />
              </TextField>
            </div>
            <Button
              className="h-10 w-full flex-none"
              size="large"
              iconRight="FeatherChevronRight"
              onClick={() => onEmailSubmit(true)}
            >
              Sign In
            </Button>
          </div>
        )}

        {!isLoading && magicLinkStep === 'code' && (
          <div className="flex h-full w-full grow shrink-0 basis-0 flex-col items-start gap-4">
            <LinkButton
              size="small"
              icon="FeatherChevronLeft"
              onClick={() => {
                setMagicLinkStep('email');
                setMagicLinkCode('');
              }}
            >
              Go back
            </LinkButton>
            <div className="flex flex-col gap-2">
              <span className="text-body font-body text-default-font ph-no-capture">
                An email with the code has been sent to {magicLinkEmail}
              </span>
              <TextField
                className="h-auto w-full flex-none"
                label=""
                helpText=""
              >
                <TextField.Input
                  placeholder="Your code"
                  value={magicLinkCode}
                  onChange={(event) => setMagicLinkCode(event.target.value)}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      void onCodeSubmit();
                    }
                  }}
                />
              </TextField>
            </div>
            <LinkButton
              size="small"
              icon={null}
              onClick={() => void onEmailSubmit(true)}
            >
              Did not get your code?
            </LinkButton>
            <Button
              className="h-10 w-full flex-none"
              size="large"
              iconRight="FeatherChevronRight"
              onClick={() => void onCodeSubmit()}
            >
              Sign In
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
