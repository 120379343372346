'use client';

import { useState } from 'react';
import { Breadcrumbs } from 'subframe/components/Breadcrumbs';
import { LearnMoreAccordion } from 'subframe/components/LearnMoreAccordion';
import { StepBase } from 'subframe/components/StepBase';
import useUserAccountState from 'hooks/useUserAccountState';
import Page from 'components/Page';
import { CopyToClipboard } from 'components/design-system/CopyToClipboard';
import { useListIngestionTokens } from 'api/frontend';
import { getUnixTime } from 'date-fns';
import TokenSelector from 'components/TokenSelector';
import { AccordionWithUnderline, CodeBlock } from 'subframe/index';

function SharcInstructionManual() {
  const { account } = useUserAccountState();
  const { data: tokens } = useListIngestionTokens('default', {
    request: {
      headers: {
        Authorization: `Bearer ${account?.token}`,
      },
    },
    swr: {
      revalidateOnFocus: false,
    },
  });
  const [selectedToken, setSelectedToken] = useState<string | undefined>();

  const activeTokens = tokens?.data.filter((token) =>
    token.revoked
      ? token.revoked == 0 || token.revoked > getUnixTime(new Date())
      : true,
  );
  const [isTokenVisible, setIsTokenVisible] = useState(false);
  const SELECT_ACCESS_TOKEN = 'Select an access token from the above dropdown.';

  return (
    <Page title="SHARC">
      <div className="flex h-full w-[calc(100%-64px)] flex-col items-start gap-6 pt-8 pb-0 px-3 bg-default-background ml-8">
        <Breadcrumbs>
          <Breadcrumbs.Item>Explore</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>SHARC</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex w-full flex-col items-start gap-2">
          <span className="w-full text-body font-body text-default-font">
            Automate your safety checks and enhance reliability with the SHARC
            (Safety Health And Readiness Checks) command-line tool. SHARC
            integrates seamlessly into your existing workflows, helping you
            reduce manual effort whether or not you are using Upgrade Plans.
          </span>

          <div className="flex w-full flex-col items-start gap-2">
            <StepBase
              stepTitle="System Requirements"
              stepBody=""
              stepNumber="1"
              actionButtons={[]}
              defaultOpen={true}
            >
              <StepBase.Container className="h-auto w-full flex-none">
                <StepBase.BulletedLine>
                  OS: Linux or macOS
                </StepBase.BulletedLine>
                <StepBase.BulletedLine>
                  Arch: x86_64 (amd64) or ARM64
                </StepBase.BulletedLine>
              </StepBase.Container>
            </StepBase>
            <StepBase
              stepTitle="Install the SHARC CLI"
              stepBody=""
              stepNumber="2"
              defaultOpen={true}
            >
              <span className="text-body font-body text-default-font">
                Download the latest version of SHARC for your OS and
                Architecture:
              </span>
              <StepBase.Container className="h-auto w-full flex-none">
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none select-none cursor-pointer"
                  text={
                    'export SHARC_VERSION="0.1.0"\nexport SHARC_PLATFORM="linux-amd64"\ncurl -O https://downloads.chkk.io/sharc/$SHARC_VERSION/sharc-$SHARC_PLATFORM.tar.gz\n'
                  }
                  singleLine={false}
                />
              </StepBase.Container>
              <span className="text-body font-body text-default-font">
                Extract the Binary:
              </span>
              <StepBase.Container className="h-auto w-full flex-none">
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none select-none cursor-pointer"
                  text="tar -xzf sharc-$SHARC_PLATFORM.tar.gz"
                  singleLine={false}
                />
              </StepBase.Container>
              <span className="text-body font-body text-default-font">
                Move the Binary to Your PATH:
              </span>
              <StepBase.Container className="h-auto w-full flex-none">
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none select-none cursor-pointer"
                  text="sudo mv sharc /usr/local/bin/"
                  singleLine={false}
                />
              </StepBase.Container>
              <span className="text-body font-body text-default-font">
                Verify Installation:
              </span>
              <StepBase.Container className="h-auto w-full flex-none">
                <CopyToClipboard
                  language="shell"
                  className="h-auto w-full flex-none select-none cursor-pointer"
                  text="sharc --version"
                  singleLine={false}
                />
              </StepBase.Container>
              <AccordionWithUnderline headerText="Sample Output:">
                <CodeBlock className="h-auto w-full flex-none">
                  v0.1.0
                </CodeBlock>
              </AccordionWithUnderline>
            </StepBase>
            <StepBase
              stepTitle="Configure the SHARC CLI"
              stepBody=""
              stepNumber="3"
              actionButtons={[]}
              defaultOpen={true}
            >
              <StepBase.Container className="h-auto w-full flex-none gap-2">
                <span className="text-body font-body text-default-font">
                  After installation, configure SHARC to authenticate with Chkk
                  SaaS using your Chkk token.
                </span>
                <span className="text-body font-body text-default-font">
                  Configure your Chkk Token:
                </span>
                <StepBase.Container className="h-auto w-full flex-none">
                  <CopyToClipboard
                    language="shell"
                    className="h-auto w-full flex-none select-none cursor-pointer"
                    text="sharc configure"
                    singleLine={false}
                  />
                </StepBase.Container>
                <span className="text-body font-body text-default-font">
                  When prompted, enter your Chkk token:
                </span>
                <CodeBlock className="h-auto w-full flex-none">
                  Provide your Chkk token:
                </CodeBlock>
                <span className="text-body font-body text-default-font">
                  Select the Access Token from the dropdown provided and Use it
                  to setup your CLI:
                </span>

                <TokenSelector
                  label=""
                  placeholder="Select a Token"
                  helpText=""
                  tokens={activeTokens || []}
                  value={selectedToken}
                  onValueChange={(value: string) => {
                    setSelectedToken(value);
                  }}
                />
                {selectedToken ? (
                  <CopyToClipboard
                    language="shell"
                    className="h-auto w-full flex-none sensitive select-none cursor-pointer"
                    text={selectedToken}
                    singleLine={false}
                  />
                ) : (
                  <CopyToClipboard
                    className="h-auto w-full flex-none select-none cursor-pointer"
                    text={SELECT_ACCESS_TOKEN}
                    singleLine={false}
                  />
                )}
              </StepBase.Container>
            </StepBase>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default SharcInstructionManual;
