import { useEffect, useState } from 'react';
import '../../../node_modules/02strich-markdown/dist/main.css';

import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import styles from './LatestPageKbaDetailsTab.module.scss';

import { useMarkdownParser } from './useMarkdownParser';
import { Tabs } from 'subframe/index';
import { CopyToClipboard } from 'src/components/design-system/CopyToClipboard';

interface ParsedKBAProps {
  mdastObj: any;
}

export const ParsedKBA = ({ mdastObj }: ParsedKBAProps) => {
  const knownType = ['code-tabs'];
  const [codeBlock, setCodeBlock] = useState<string[]>([]);
  const [currentTab, setCurrentTab] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const { logEvent } = AnalyticsEventLogger();
  const isKnownType = knownType.includes(mdastObj.type);

  const getTabName = (tab) => {
    return tab.meta === null ? tab.lang : tab.meta;
  };

  useEffect(() => {
    //by default selects the first code block tab
    if (currentTab.length < 1 && codeBlock?.length > 0) {
      setCurrentTab(getTabName(codeBlock[0]));
    }
  }, [codeBlock]);

  useEffect(() => {
    try {
      if (mdastObj.type === 'code-tabs') {
        setCodeBlock(mdastObj.children);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      return error.toString();
    }
  }, [mdastObj]);

  const { parse } = useMarkdownParser();
  return (
    <div style={{ width: '100%' }}>
      {!loading && (
        <>
          {mdastObj.type === 'code-tabs' && (
            <>
              <Tabs className="h-auto w-auto flex-none">
                {codeBlock
                  // TODO: Filter removes all Coming Soon tabs
                  // https://github.com/chkk-io/sprints/issues/2425
                  .filter(
                    (tab) =>
                      !(
                        getTabName(tab)
                          .toLocaleLowerCase()
                          .includes('pulumi') ||
                        getTabName(tab)
                          .toLocaleLowerCase()
                          .includes('terraform')
                      ),
                  )
                  .map((tab) => (
                    <Tabs.Item
                      active={getTabName(tab) === currentTab}
                      key={getTabName(tab)}
                      onClick={() => {
                        setCurrentTab(getTabName(tab));
                        logEvent('kba-codeblock-tab-change', {
                          newTab: getTabName(tab),
                        });
                      }}
                    >
                      {getTabName(tab)}
                    </Tabs.Item>
                  ))}
              </Tabs>
              {codeBlock.map((tab) => {
                const isMatched = getTabName(tab) === currentTab;
                return (
                  isMatched && (
                    <div className={styles['longTermFixCommandText']}>
                      <CopyToClipboard text={tab?.value} language={tab.lang} />
                    </div>
                  )
                );
              })}
            </>
          )}
          {!isKnownType && <>{parse([mdastObj])}</>}
        </>
      )}
    </div>
  );
};
